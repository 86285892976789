<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align:right">
      <c-btn
        label="사진설명 저장"
        icon="save"
        v-if="editable&&!disabled"
        @btnClicked="saveData"/>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:25px !important;">
      <c-upload 
        :attachInfo="attachInfo"
        :editable="editable&&!disabled"
        :isPhotoView="true"
        label="작업결과 사진첨부">
      </c-upload>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
export default {
  name: 'work-order-result-file',
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        copyFlag: '',
        firstCopy: false,
        workResultId: '',
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WO_RESULT',
        taskKey: '',
      },
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    apprDisabled() {
      return this.disabledAppr;
    },
    disabled() {
      return this.disabledObj.disabled
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo, 'taskKey', this.param.workPlanId)
    },
    saveData() {
      this.$set(this.attachInfo, 'taskKey', this.param.workPlanId)
      this.$set(this.attachInfo, 'isSubmit', uid())
    },
  }
};
</script>
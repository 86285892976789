var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
        staticStyle: { "text-align": "right" },
      },
      [
        _vm.editable && !_vm.disabled
          ? _c("c-btn", {
              attrs: { label: "사진설명 저장", icon: "save" },
              on: { btnClicked: _vm.saveData },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
        staticStyle: { "padding-top": "25px !important" },
      },
      [
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfo,
            editable: _vm.editable && !_vm.disabled,
            isPhotoView: true,
            label: "작업결과 사진첨부",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }